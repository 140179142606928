html { 
  font-size: 18px; 
}

$atlas: 'Atlas Grotesk Web', sans-serif;
$austin:  'Austin News Deck Web', serif;

.atlas {
  font-family: $atlas;
}

.austin {
  font-family: $austin;
}

.serif {
  font-family: 'Austin News Deck Web', serif;
}

.sans-serif {
  font-family: 'Atlas Grotesk Web', sans-serif;
}

.italic {
  font-style: italic;
}

a {
  text-decoration: none;

  &:visited {
    color: color('black');
  }
}

h1, h2, h3, h4, h5, h6, p, div, span, a, img, th, td {
  &::selection {
    color: color('white');
    background-color: color('blue');
  }
}

.text-title, .text-menu-lg, h1, h2, h3, h4, h5, p.serif {
  font-weight: 100;
}

h2 {
  font-size: 2rem;
}

h5 {
  font-size: 3.5rem;
}

p.sans-serif, li.sans-serif {
  line-height: 1.72rem;
  font-size: 0.66rem;

  @include media('md-up') {
    font-size: 1rem;
  }
}

.text-title, .text-menu-lg, .text-subtitle, .text-caption {
  font-family: $austin;
}

.text-title {
  font-size: 13vw;
  line-height: auto;

  @include media('md-up') {
    font-size: 6.5vw;
  }
}

.text-subtitle {
  font-size: 1.27rem;
  line-height: 1.5rem;

  @include media('md-up') {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

.text-menu-lg,
%text-menu-lg {
  font-family: $austin;
  font-weight: 100;
  font-size: 9vw;
  line-height: 11vw;

  @include media('md-up') {
    font-size: 3.2rem;
    line-height: 3.8rem;
  }
}

.text-menu-md {
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-weight: 100;

  @include media('md-up') {
    font-size: 1rem;
    line-height: 1.4rem;
  }
}

.text-button,
%text-button {
  font-family: $atlas;
  font-size: 0.66rem;

  @include media('md-up') {
    font-size: .77rem;
  }
}

.text-caption, .text-paragraph {
  > a {
    transition: all $transition-easing $transition-duration-shorter;
    color: color('black');
    
    &:hover {
      color: color('gray');
    }
  }
}

.text-caption {
  font-weight: 100;
  font-size: 0.6rem;
  line-height: 0.8rem;
  letter-spacing: .75px;

  @include media('lg-up') {
    font-size: 0.77rem;
    line-height: 1.1rem;
  }
}

.text-footer-sm {
  font-family: $atlas;
  font-size: 0.61rem;
  line-height: 0.66rem;

  @include media('md-up') {
    font-size: 0.77rem;
    line-height: 1.11rem;
  }
}

.text-footer-xs {
  font-family: $atlas;
  font-size: 0.55rem;
  line-height: 0.77rem;

  @include media('md-up') {
    font-size: 0.77rem;
    line-height: 1.11rem;
  }
}

.text-paragraph {
  font-size: 0.8rem;
  line-height: 1.3rem;

  @include media('md-up') {
    font-size: 1rem;
    line-height: 1.7rem;
  }
}

.text-details {
  font-family: $atlas;
  font-size: 0.66rem;

  @include media('md-up') {
    font-size: 0.77rem;
  }
}

ol {
  list-style: none;
}

.list-number {
  min-width: 2.5rem;
  max-width: 2.5rem;
}

.resources-list > li > a {
  text-decoration: none;
  color: color('gray');
}
