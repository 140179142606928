@import 'config';
@import '~@sanctucompu/basement/src/index';
@import 'settings', 'reset', 'typography', 'sizing';

//View

.view {
  @extend %transition;

  &--menu-is-active {
    transform: translate3d(0, 2rem, 0);
    transform-style: preserve-3d;
  }
}

.wrapper,
.header {
  margin-left: 1.11rem;
  margin-right: 1.11rem;
}

.full-width-content-container {
  display: flex;
  margin-bottom: 5rem;

  @include media('md-up') {
    margin-bottom: 12rem;
  }
}

.smaller-content-container {
  display: flex;
  margin-bottom: 5rem;

  @include media('md-up') {
    margin-bottom: 12rem;
  }

  @include media('xl-up') {
    max-width: 80rem;
  }
}

.inner-content-padding-x {
  @include media('xl-up') {
    padding-left: $inner-content-padding-x-desktop;
    padding-right: $inner-content-padding-x-desktop;
  }
}

.inner-content-padding-x-left {
  @include media('xl-up') {
    padding-left: $inner-content-padding-x-desktop;
  }
}

.inner-content-padding-x-right {
  @include media('xl-up') {
    padding-right: $inner-content-padding-x-desktop;
  }
}

.column-margin-padding-left {
  @include media('md-up') {
    padding-left: 2.3%;
  }
}

//Buttons

button {
  cursor: pointer;
}

.button--style-nav {
  @extend %transition-shorter;
  background-color: transparent;
  padding: 0;
  text-decoration: none;
  color: color('black');

  &:hover {
    color: color('gray')
  }
}

.button--style-primary {
  @extend %text-button;
  @extend %transition-shorter;
  padding: 6px 20px;
  width: 12.22rem;
  background-color: transparent;
  border-width: 1px;
  border-color: color('black');
  border-style: solid;
  border-radius: 999px;
  cursor: pointer;

  &:hover {
    background-color: color('black');
    color: color('white');
  }

  @include media('md-up') {
    width: 16rem;
  }
}

.button--style-primary {
  @extend %text-button;
  @extend %transition-shorter;
  padding: 6px 20px;
  width: 12.22rem;
  background-color: transparent;
  border-width: 1px;
  border-color: color('black');
  border-style: solid;
  border-radius: 999px;
  cursor: pointer;

  &:hover {
    background-color: color('black');
    color: color('white');
  }

  @include media('md-up') {
    width: 16rem;
  }
}

footer {
  img {
    max-width: 80px;
  }
}

//Nav

.nav-menu-button-container {
  background-color: rgba(255, 255, 255, 0.95);
  background: linear-gradient(to bottom,#fff 0,rgba(255, 255, 255, 0));
  padding-right: $nav-padding-x;
  padding-left: $nav-padding-x;

  @include media('xl-up') {
    padding-left: 0;
    padding-right: $nav-padding-x;
  }
}

.studio-carbon-negative-button-container {
  padding-left: $nav-padding-x;

  @include media('md-up') {
    padding-left: $nav-padding-x;
  }

  @include media('xl-up') {
    background: linear-gradient(to bottom,#fff 0,rgba(255, 255, 255, 0));
  }
}

.nav-menu-button-container,
.studio-carbon-negative-button-container {
  height: $nav-height-mobile;

  button {
    @extend %transition-shorter;
    &:hover {
      color: color('gray')
    }
  }

  @include media('md-up') {
    height: $nav-height-desktop;
  }
}

.studio-carbon-negative-button {
  &--inactive {
    transform: translate3d(0, -2rem, 0);
    transform-style: preserve-3d;
    opacity: 0;
  }

  &--active {
    opacity: 1;
  }
}

.main-nav,
.inner-nav {
  height: $nav-height-mobile;
  padding-left: $nav-padding-x;
  padding-right: $nav-padding-x;

  button {
    @extend %transition-shorter;
    &:hover {
      color: color('gray')
    }
  }

  @include media('md-up') {
    height: $nav-height-desktop;
    padding-left: $nav-padding-x;
    padding-right: $nav-padding-x;
  }
}

.menu {
  @extend %transition;
  transform-style: preserve-3d;
  background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 18%);
  height: 36rem;

  @include media('md-sm-up') {
    height: 40rem;
  }

  @include media('md-up') {
    height: 36rem;
  }

  &--active {
    opacity: .95;
    z-index: 900;
  }
}

.menu-active-link {
  color: color('gray')!important;
  pointer-events: none;
}


.menu-links {
  &--fade-in {
    div:nth-child(1) {
      animation: $transition-duration-short cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.1s 1 normal forwards animation-fade-in-down-short;
    }

    div:nth-child(2) {
      animation: $transition-duration-short cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.15s 1 normal forwards animation-fade-in-down-short;
    }

    div:nth-child(3) {
      animation: $transition-duration-short cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s 1 normal forwards animation-fade-in-down-short;
    }

    div:nth-child(4) {
      animation: $transition-duration-short cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.25s 1 normal forwards animation-fade-in-down-short;
    }

    div:nth-child(5) {
      animation: $transition-duration-short cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s 1 normal forwards animation-fade-in-down-short;
    }

    div:nth-child(6) {
      animation: $transition-duration-short cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.35s 1 normal forwards animation-fade-in-down-short;
    }
  }
}

.inner-menu-container {
  padding-left: $nav-padding-x;
  padding-right: $nav-padding-x;

  @include media('xl-up') {
    padding-left: 0;
    padding-right: 0;
  }

  &--animation-fade-in-down-slow {
    animation: 0.5s $transition-easing 0s 1 normal forwards animation-fade-in-down-slow;
  }

  &--animation-fade-in-up-slow {
    animation: 0.5s $transition-easing 0s 1 normal forwards animation-fade-in-up-slow;
  }
}

.inner-menu {
  padding-top: $nav-height-desktop;
  max-width: 80rem;

  button > a {
    @extend %text-menu-lg;
  }
}

.table-of-contents {
  transition: all 1s $transition-easing;

  @include media('xl-up') {
    transition: all $transition-duration-short $transition-easing;
  }
}

//Index

.background-slide {
  animation: $transition-duration-long $transition-easing .25s 1 normal forwards animation-fade-in;
}

.map-container:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.map img {
  -webkit-animation: spin 100s linear infinite;
  -moz-animation: spin 100s linear infinite;
  animation: spin 100s linear infinite;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.map-container {
  transform-style: preserve-3d;
  width: 90vw;
  max-width: 27.77rem;

  @include media('sm-up') {
    top: 10vh;
  }

  @include media('md-up') {
    top: 0%;
    width: 55vw;
    min-width: 27.77rem;
    max-width: 38.88rem;
  }

  @include media('xl-up') {
    width: 45vw;
    min-width: 36.11rem;
    max-width: 50rem;
  }
}

.studio {
  animation: $transition-duration-longer $transition-cubic-bezier 0s 1 normal forwards animation-fade-in-up-shortest;
  margin-top: 12vh;

  @include media('md-up') {
    animation: $transition-left-title;
    margin-top: 40vh;
  }
}

.negative {
  animation: $transition-duration-longer $transition-cubic-bezier .2s 1 normal forwards animation-fade-in-down-shortest;

  @include media('md-up') {
    animation: $transition-right-title;
    margin-top: 40vh;
  }
}

.header-image-container {
  transform-style: preserve-3d;
  animation: $transition-duration-long $transition-cubic-bezier .5s 1 normal forwards animation-fade-in-up;
  top: 55vh;
  left: 18vw;
  max-height: 8.61rem;

  @include media('md-up') {
    max-height: 13.94rem;
    top: 35vh;
    left: 28%;
  }

  @include media('lg-up') {
    left: 32%;
  }

  @include media('xl-up') {
    top: 30vh;
    left: 28%;
  }
}

.index-image-two-container {
  @include media('md-up') {
    margin-top: 15rem;
  }
}

.index-image-seven-container {
  @include media('md-up') {
    margin-top: 20rem;
  }
}

//Calculating Emissions
.calculating {
  animation: $transition-left-title;
  margin-top: 10vh;

  @include media('md-up') {
    margin-top: 35vh;
  }

  @include media('lg-up') {
    margin-top: 45vh;
  }

  @include media('xxl-up') {
    margin-top: 50vh;
  }
}

.emissions {
  animation: $transition-right-title;
  margin-top: 57vh;

  @include media('sm-up') {
    margin-top: 70vh;
  }

  @include media('md-up') {
    margin-top: 25vh;
    padding-right: 8%;
  }

  @include media('xl-up') {
    margin-top: 25vh;
  }
}

.emissions-header-image-container {
  transform-style: preserve-3d;
  animation: $transition-duration-long $transition-cubic-bezier .5s 1 normal forwards animation-fade-in-up;
  top: 23vh;
  left: 1.112rem;
  max-height: 20rem;

  @include media('sm-up') {
    top: 22vh;
  }

  @include media('md-up') {
    top: 18vh;
    left: 35%;
  }

  @include media('xl-up') {
    top: 10vh;
    left: 35%;
  }
}

.emissions-caption {
  animation: $transition-duration-long $transition-easing .75s 1 normal forwards animation-fade-in;
  top: 90vh;
  right: 1.112rem;

  @include media('md-up') {
    top: 75vh;
    right: 20%;
  }

  @include media('xl-up') {
    right: 15%;
  }
}

.measuring-emissions-caption {
  @include media('xl-up') {
    margin-top: 40%;
  }
}

.emissions-diagram-caption {
  @include media('xl-up') {
    padding-top: 35%;
  }
}

.emissions-diagram-table-container, .emissions-diagram-container {
  @include media('xl-up') {
    padding-right: $inner-content-padding-x-desktop;
  }
}

.emissions-diagram-table > :last-child {
  border-bottom: 1px solid color('black');
}

.emissions-diagram-table-item {
  padding-top: 0.66rem;
  padding-bottom: 0.66rem;
  border-top: 1px solid color('black');

  @include media('md-up') {
    padding-top: 1.77rem;
    padding-bottom: 1.77rem;
  }
}

.emissions-image-two-container {
  @include media('md-up') {
    margin-top: -15%;
  }

  @include media('lg-up') {
    margin-top: 20rem;
  }
}

.emissions-image-four-container {
  @include media('md-up') {
    margin-top: 15rem;
  }
}

table {
  border-collapse: collapse;
}

tr:last-of-type {
  border-bottom: 1px solid color('black');
}

.table-header > th {
  padding-bottom: 0.66rem;
}

.table-items > td {
  padding-top: 0.55rem;
  padding-bottom: 0.55rem;
  border-top: 1px solid color('black');
}

//Buying Offsets
.buying {
  animation: $transition-left-title;
  margin-top: 10vh;

  @include media('md-up') {
    margin-top: 15vh;
  }
}

.offsets {
  animation: $transition-right-title;
  margin-top: 63vh;

  @include media('sm-down') {
    display: flex;
    justify-content: flex-end;
  };

  @include media('sm-up') {
    margin-top: 65vh;
  }

  @include media('md-up') {
    margin-top: 35vh;
  }
}

.offsets-header-image-container {
  transform-style: preserve-3d;
  animation: $transition-duration-long $transition-cubic-bezier .5s 1 normal forwards animation-fade-in-up;
  top: 18vh;

  @include media('md-up') {
    top: 22vh;
  }
}

.offsets-caption {
  animation: $transition-duration-long $transition-easing .75s 1 normal forwards animation-fade-in;
  bottom: -5%;

  @include media('md-up') {
    bottom: 5%;
  }
}

.offsetting-technologies-caption {
  @include media('lg-up') {
    margin-top: 60%;
  }
}

//Helping Clients
.helping {
  animation: $transition-left-title;
  margin-top: 10vh;

  @include media('md-up') {
    margin-top: 25vh;
  }

  @include media('lg-up') {
    margin-top: 30vh;
  }

  @include media('xl-up') {
    margin-top: 35vh;
  }
}

.clients {
  animation: $transition-right-title;
  margin-top: 65vh;

  @include media('md-up') {
    margin-top: 25vh;
  }


  @include media('lg-up') {
    margin-top: 30vh;
  }

  @include media('xl-up') {
    margin-top: 35vh;
  }
}

.clients-header-image-container {
  transform-style: preserve-3d;
  animation: $transition-duration-long $transition-cubic-bezier .5s 1 normal forwards animation-fade-in-up;
  top: 20vh;
  max-height: 20rem;

  @include media('md-up') {
    top: 18vh;
  }

}

.clients-caption {
  animation: $transition-duration-long $transition-easing .75s 1 normal forwards animation-fade-in;
  top: 95vh;
  right: 1.112rem;

  @include media('md-up') {
    top: 85vh;
    right: 20%;
  }

  @include media('xl-up') {
    right: 15%;
  }
}

.clients-diagram-caption {
  @include media('xl-up') {
    margin-top: 60%;
  }
}

.clients-image-three-container {
  @include media('md-up') {
    margin-top: -2%;
  }
}

//Resources
.resources-section-container {
  animation: $transition-duration-long $transition-easing 0s 1 normal forwards animation-fade-in;
}

.resources-list-container {
  @include media('md-up') {
    margin-top: 12rem;
  }
}

.resources-list {
  > li > a {
    @extend %transition-shorter;
    color: color('black');
    text-decoration: none;

    &:hover {
      color: color('gray')
    }
  }
}

//Footer

.footer-logo-container > img {

  @include media('sm-down') {
    max-width: 3.44rem;
  }
}

.footer-padding-x {
  padding-left: 1.11rem;
  padding-right: 1.11rem;
}

.footer-header {
  padding-left: 1.11rem;
  padding-right: 1.11rem;

  @include media('lg-up') {
    padding-left: 0;
    padding-right: 0;
  }
}

.footer-menu {
  padding-bottom: 10rem;

  > div > a {
    color: color('black');
    text-decoration: none;
    @extend %transition-shorter;

    &:hover {
      color: color('gray')
    }
  }
}

.color-img {
  font-size: 0;
  line-height: 0;
  background-color: color('blue');

  > img {
    mix-blend-mode: lighten;
  }
}
