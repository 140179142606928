$z: (
  'below': -1,
  '0': 0,
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  '5': 5,
  'nav': 700,
  'overlay': 1000,
  '2000': 2000,
  '3000': 3000
);

@function z($index) {
  @return map-get($z, $index);
}

@each $name, $z-index in $z {
  .z-#{$name} {
    z-index: #{$z-index};
  }
}
