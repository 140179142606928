$transition-duration-shorter: 0.18s;
$transition-duration-short: 0.4s;
$transition-duration-medium: 0.5s;
$transition-duration-medium-long: 850ms;
$transition-duration-long: 950ms;
$transition-duration-longer: 3.2s;
$transition-easing: ease-in-out;
$transition-cubic-bezier: cubic-bezier(0, 0.12, 0.11, 0.71);
$transition: $transition-duration-short $transition-easing;
$transition-left-title: $transition-duration-longer $transition-cubic-bezier 0s 1 normal forwards animation-fade-in-up-long;
$transition-right-title: $transition-duration-longer $transition-cubic-bezier .2s 1 normal forwards animation-fade-in-down;

.transition-shortest,
%transition-shortest {
  transition: all 0.05s $transition-easing;
}

.transition-shorter,
%transition-shorter {
  transition: all $transition-duration-shorter $transition-easing;
}

.transition-short,
%transition-short {
  transition: all $transition-duration-short $transition-easing;
}

.transition,
%transition {
  transition: all $transition-duration-medium $transition-cubic-bezier;
}

.transition-long,
%transition-long {
  transition: all $transition-duration-long $transition-cubic-bezier;
}

.transition-longer,
%transition-longer {
  transition: all $transition-duration-medium-long $transition-cubic-bezier;
}

@keyframes animation-fade-in-down-slow {
  35% {
    opacity: 0;
    transform: translate3d(0, -1rem, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes animation-fade-in-down-shortest {
  0% {
    opacity: 0;
    transform: translate3d(0, -.35rem, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes animation-fade-in-down-short {
  0% {
    opacity: 0;
    transform: translate3d(0, -1rem, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes animation-fade-in-down {
  0% {
    opacity: 0;
    transform: translate3d(0, -2.5rem, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes animation-fade-in-up-slow {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -1rem, 0);
  }
}

@keyframes animation-fade-in {
  0% {
    opacity: 0;
    transform: translate3d(0, 0rem, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0rem, 0);
  }
}

@keyframes animation-fade-in-up-shortest {
  0% {
    opacity: 0;
     transform: translate3d(0, .35rem, 0)
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}


@keyframes animation-fade-in-up {
  0% {
    opacity: 0;
    transform: translate3d(0, 2rem, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0rem, 0);
  }
}

@keyframes animation-fade-in-up-long {
  0% {
    opacity: 0;
     transform: translate3d(0, 3.5rem, 0)
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fade-in {
  opacity: 0;

  &.visible {
    animation: $transition-duration-medium-long $transition-easing 0s 1 normal forwards animation-fade-in;
  }
}
